<template>
  <div>

    <h1 class="tit mb-3 mt-6">Premium i pagament</h1>

    <div v-if="!loading">

      <!------------------------------>
      <!-- Subscription Information -->
      <!------------------------------>
      <div
        v-if="plan"
        class="py-6 align-end"
      >
        <!------------------------------>
        <!-- Active plan              -->
        <!------------------------------>
        <div v-if="!plan.cancel_at_period_end">
          <v-card
            style="height: fit-content"
            width="100%"
            max-width="700"
            class="d-flex flex-column rounded-xl shadow-sm justify-center align-center text-center pa-10">


            <div class="d-flex align-center">
              <v-avatar color="primary">
                <v-icon size="23" color="white">{{ mdiCheck() }}</v-icon>
              </v-avatar>
              <div class="d-flex flex-column ml-3">
                <span class="font-weight-bold font-rubik"
                      style="font-size: 24px; letter-spacing: -1px; color: #256EFF"
                >
                  examenselectivitat
                </span>
                <div
                  style="margin-top: -6px; width: fit-content"

                >
                <span
                  style="width: fit-content; font-size: 12px"
                  class="font-weight-medium orange font-rubik white--text pa-1 rounded">Premium</span>
                </div>
              </div>
            </div>


            <div class="py-10">
              <span class="font-rubik font-weight-medium">
                La teva subscripció es renovarà automàticament el {{ new Date(plan.current_period_end).toLocaleDateString() }}
                <br>
                i cada mes et cobrarem {{ plan.amount }} €
              </span>
            </div>


            <v-btn
              @click="cancelDialog=true"
              width="fit-content"
              depressed
              class="mx-auto text-none font-rubik px-10"
            >Cancel·lar
            </v-btn>

          </v-card>

          <v-dialog v-model="cancelDialog"
                    max-width="700"
          >
            <v-card class="d-flex flex-column justify-center align-center text-center pa-10">

              <span
                style="font-size: 30px"
                class="font-weight-medium font-rubik">Cancel·lar premium</span>

              <span class="mt-2 mb-10">Estàs segur que vols cancel·lar la teva subscripció premium?</span>

              <div class="d-flex">
                <v-btn
                  @click="cancelPlan"
                  width="fit-content"
                  depressed
                  class="mx-auto text-none font-rubik px-10"
                >
                  Sí, Cancel·lar
                </v-btn>
                <v-btn
                  @click="cancelDialog=false"
                  width="fit-content"
                  depressed outlined
                  class="mx-auto text-none font-rubik px-10 ml-3"
                >
                  No, Tornar
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </div>


        <!------------------------------>
        <!-- Cancelled plan           -->
        <!------------------------------>
        <div v-else
             class="d-flex flex-wrap"
        >

          <v-card
            style="max-width: fit-content; height: fit-content"
            class="d-flex flex-column rounded-xl shadow-sm">

            <div class="px-8 py-6">
            <span
              class="font-rubik font-weight-medium"
              style="font-size: 20px"
            >
              Estat de la subscripció:
            </span>
            </div>

            <v-divider></v-divider>


            <div class="pa-8">
              <v-alert class="font-weight-medium font-rubik mb-6 rounded-lg"
                       type="warning"
                       border="left"
                       max-width="600"
                       :icon="mdiAlert()"
              >
                Has cancel·lat l'abonament Premium.
              </v-alert>

              <span class="font-rubik font-weight-medium"
              >
              La teva subscripció acabarà el {{ new Date(plan.current_period_end).toLocaleDateString() }}.
            </span>
            </div>
          </v-card>

          <!-- REACTIVATE PLAN --->
          <v-card
            min-width="350"
            class="ml-10 rounded-xl shadow-sm">
            <div class="px-8 py-6">
              <span
                class="font-rubik font-weight-medium"
                style="font-size: 20px"
              >
                Si vols tornar a activar <br>la subscripció:
              </span>
            </div>

            <v-divider></v-divider>
            <abonaments
              dense flat></abonaments>

          </v-card>
        </div>

      </div>


      <!----------------------------->
      <!-- Sense plan              -->
      <!----------------------------->
      <div v-else class="d-flex flex-column justify-center">
        <v-alert>No tens el plan Premium activat.</v-alert>
        <!-- REACTIVATE PLAN --->
        <v-card
          max-width="350"
          class="mt-10 rounded-xl shadow-sm mx-auto">
          <div class="px-8 py-6">
              <span
                class="font-rubik font-weight-medium"
                style="font-size: 20px"
              >
                Estudia sense anuncis ni distraccions:
              </span>
          </div>

          <v-divider></v-divider>
          <div>
            <abonaments
              dense flat></abonaments>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import {mdiAlert, mdiCheck} from "/src/assets/mdi.json";
import Abonaments from "../../premium/components/Abonaments.vue";

export default {
  name: "PremiumConfig",
  components: {Abonaments},
  async mounted() {
    await this.fetchPremiumPlan()
  },
  data() {
    return {
      plan: null,
      loading: true,
      cancelDialog: false,
    }
  },
  computed: {
    user() {
      return this.$root.currentUser.value
    }
  },
  methods: {
    mdiCheck() {
      return mdiCheck
    },
    mdiAlert() {
      return mdiAlert
    },
    async fetchPremiumPlan() {
      this.loading = true;
      const {data} = await this.axios.post('/premium/plan', {
        user_token: this.$cookies.get("token")
      })

      this.plan = data;
      this.loading = false;
    },
    async cancelPlan() {
      const {data} = await this.axios.post('/premium/del', {
        user_token: this.$cookies.get("token")
      })

      await this.fetchPremiumPlan()

    }
  }
}
</script>

<style scoped>

</style>
