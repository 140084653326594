<template>
  <div>

    <h1 class="tit mb-3 mt-6">Sessio i seguretat</h1>


    <!----------------------------->
    <!-- Nom                     -->
    <!----------------------------->
    <div class="py-6 align-end"
         :class="modifyPass ? '' : 'd-flex'"
         style="border-bottom: 1px solid #c7c7c7"
         :set="item = 0"
    >
      <div class="d-flex flex-column" style="flex: 1">
        <span class="font-weight-medium">Contrasenya</span>
        <span
          v-if="!modifyPass"
          class="body-2 mt-2">********</span>
        <div v-else>
          <v-text-field v-model="oldPass"
                        label="Contrasenya actual"
                        :append-icon="showOldPass ? mdiEye : mdiEyeOff"
                        :type="showOldPass ? 'password' : 'text'"
                        @click:append="showOldPass=!showOldPass"
                        dense solo class="mt-2"
                        style="max-width: 350px"
                        flat outlined></v-text-field>
          <v-text-field v-model="newPass"
                        label="Nova contrasenya"
                        :append-icon="showNewPass ? mdiEye : mdiEyeOff"
                        :type="showNewPass ? 'password' : 'text'"
                        @click:append="showNewPass=!showNewPass"
                        style="max-width: 350px"
                        dense solo class="mt-7"
                        flat outlined></v-text-field>
          <v-text-field v-model="newPass2"
                        :append-icon="showNewPass2 ? mdiEye : mdiEyeOff"
                        :type="showNewPass2 ? 'password' : 'text'"
                        @click:append="showNewPass2=!showNewPass2"
                        style="max-width: 350px"
                        label="Comprova la nova contrasenya"
                        dense solo class="mt-2"
                        flat outlined></v-text-field>
        </div>
      </div>

      <span v-if="errMsg" class="caption red--text">{{ errMsg }}</span>

      <v-btn v-if="!modifyPass"
             @click="modifyPass = !modifyPass"
             color="white" depressed class="text-none">
        Modificar
      </v-btn>

      <div v-else class="d-flex mt-6" style="max-width: 350px">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            @click="modifyPass = !modifyPass"
            color="white" depressed class="text-none">
            Cancel
          </v-btn>

          <v-btn
            @click="savePassword"
            :disabled="oldPass && newPass && newPass !== newPass2"
            color="primary" depressed class="text-none ml-2">
            Guardar
          </v-btn>
        </div>
      </div>
    </div>


    <!----------------------------->
    <!-- Delete account          -->
    <!----------------------------->
    <div class="py-6 align-end"
         style="border-bottom: 1px solid #c7c7c7"
         :set="item = 1"
    >
      <div class="d-flex flex-column" style="flex: 1">
        <span class="font-weight-medium mb-2">Eliminar el compte</span>
        <span>En eliminar el teu compte, ja no podràs accedir a cap de les teves col·leccions de problemes ni iniciar sessió en examenselectivitat.cat</span>
      </div>
      <v-btn @click="deleteDialog = true"
             color="white" depressed class="text-none mt-5 px-12">
        Eliminar el compte
      </v-btn>

    </div>


  </div>
</template>

<script>
import { mdiEye } from '/src/assets/mdi.json'
import { mdiEyeOff } from '/src/assets/mdi.json'

export default {
  name: "ElMeuCompte",
  data() {
    return {
      oldPass: "",
      newPass: "",
      newPass2: "",
      modifyPass: false,
      deleteDialog: false,
      showOldPass: false,
      showNewPass: false,
      showNewPass2: false,
      errMsg: null,
      mdiEye: mdiEye,
      mdiEyeOff: mdiEyeOff,
    }
  },
  computed: {
    user() {
      return this.$root.currentUser.value
    }
  },
  methods: {
    async savePassword() {
      try {
        await this.axios.post("/profile/password", {
          user_id: this.user.user_id,
          old_password: this.oldPass,
          new_password: this.newPass
        })
        this.modifyPass = false;

      } catch (err) {

        if (err.response.status === 401) {
          this.errMsg = err.response.data.message;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
