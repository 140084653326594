<template>
  <div v-if="user" class="d-flex">

    <!----------------------------->
    <!-- Left Drawer             -->
    <!----------------------------->
    <v-navigation-drawer
      permanent
      clipped height="100%" style="min-height: 100%; height: 100%">
      <v-card height="100%" class="pa-1">
        <v-list
          style="height: 100vh; min-height: 100%;"
          nav
          dense
        >
          <v-list-item class="d-flex py-4">
            <v-avatar
              :size="$vuetify.breakpoint.smAndDown ? 48 : 40"
              color="primary"
              class="white--text  text-uppercase subtitle-2 tit"
            >
              <span>{{ user.user_name.substring(0, 1) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ml-2">
            <span class="ml-2 font-weight-medium" style="color: #505a71; font-size: 14px">{{
                user.user_name
              }}</span>
              <span class="ml-2" style="color: #B3BAC5; font-size: 12px">{{
                  user.user_email
                }}</span>
            </div>
          </v-list-item>

          <v-list-item
            v-for="(page, idx) in pages"
            @click="selectedPage = idx"
            class="justify-start align-center mb-1"
            link>
            <v-list-item-icon class="ml-0 mr-3">
              <v-icon color="#0d1216">{{ page.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="tit" style="font-size: 14px !important">
              {{ page.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>

    <!----------------------------->
    <!-- Main View               -->
    <!----------------------------->
    <v-container class="mx-auto px-12" style="flex: 1; max-width: 1400px !important;">
      <el-meu-compte v-if="selectedPage === 0"></el-meu-compte>

      <sessio-i-seguretat v-else-if="selectedPage === 1"></sessio-i-seguretat>

      <premium-config  v-else-if="selectedPage === 2"></premium-config>

      <add-my-subjects-dialog v-else-if="selectedPage === 3"
                              :value="true"
                              :my-subjects="subjects"
                              @fetchMySubjects="fetchMySubjects"
      ></add-my-subjects-dialog>
    </v-container>

  </div>
</template>

<script>
import ElMeuCompte from "./ElMeuCompte";
import SessioISeguretat from "./SessioISeguretat";
import AddMySubjectsDialog from "../mySubjects/AddMySubjectsDialog";

import {mdiAccountCircleOutline, mdiStarOutline} from '/src/assets/mdi.json'
import {mdiLockOutline } from '/src/assets/mdi.json'
import {mdiBookOutline } from '/src/assets/mdi.json'
import PremiumConfig from "./PremiumConfig.vue";


export default {
  name: "ConfigPage",
  components: {
    PremiumConfig,
    AddMySubjectsDialog,
    ElMeuCompte,
    SessioISeguretat
  },
  data() {
    return {
      selectedPage: 0,
      pages: [
        {
          title: "El teu compte",
          icon: mdiAccountCircleOutline
        },
        {
          title: "Sessió i seguretat",
          icon: mdiLockOutline
        },
        {
          title: "Premium",
          icon: mdiStarOutline
        },
        {
          title: "Assignatures",
          icon: mdiBookOutline
        }
      ],
      subjects: []
    }
  },
  computed: {
    user() {
      return this.$root.currentUser.value
    }
  },
  methods: {
    async fetchMySubjects() {
      try {
        const {data} = await this.axios.get('/my-subjects', {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.subjects = data;
      } catch (e) {
        throw e
      }
    }
  },
  watch: {
    selectedPage(val) {
      if (val === 2) {
        this.fetchMySubjects();
      }
    }
  }
}
</script>

<style >


</style>
