<template>
  <div>

    <h1 class="tit mb-3 mt-6">El meu compte</h1>


    <!----------------------------->
    <!-- Nom                     -->
    <!----------------------------->
    <div class="py-6 align-end"
         :class="modifyName ? '' : 'd-flex'"
         style="border-bottom: 1px solid #c7c7c7"
    >
      <div class="d-flex flex-column" style="flex: 1">
        <span class="font-weight-medium">Nom</span>
        <span
          v-if="!modifyName"
          class="body-2 mt-2">{{ name }}</span>
        <v-text-field v-else
                      v-model="name"
                      dense solo class="mt-2"
                      flat outlined></v-text-field>
      </div>
      <v-btn v-if="!modifyName"
             @click="modifyName=!modifyName"
             color="white" depressed class="text-none">
        Editar
      </v-btn>

      <div v-else class="d-flex mt-2">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            @click="modifyName=!modifyName"
            color="white" depressed class="text-none">
            Cancel
          </v-btn>

          <v-btn
            @click="saveName"
            color="primary" depressed class="text-none ml-2">
            Guardar
          </v-btn>
        </div>
      </div>
    </div>


    <!----------------------------->
    <!-- Email                   -->
    <!----------------------------->
    <div class="py-6 align-end"
         :class="modifyEmail ? '' : 'd-flex'"
         style="border-bottom: 1px solid #c7c7c7"
    >
      <div class="d-flex flex-column" style="flex: 1">
        <span class="font-weight-medium">Email</span>
        <span
          v-if="!modifyEmail"
          class="body-2 mt-2">{{ email }}</span>
        <v-text-field v-else
                      v-model="email"
                      dense solo class="mt-2"
                      flat outlined></v-text-field>
      </div>

    </div>


    <!----------------------------->
    <!-- School                  -->
    <!----------------------------->
    <div class="py-6 align-end">
      <div class="d-flex flex-column" style="flex: 1">
        <span class="font-weight-medium">Institut</span>
        <v-autocomplete
          v-if="instituts"
          v-model="school"
          @change="saveSchool"
          :items="instituts"
          style="max-width: 350px"
          label="Selecciona el teu institut"
          dense solo class="mt-2 config-selector"
          flat outlined></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ElMeuCompte",
  data() {
    return {
      editingFields: [],
      instituts: null,
      name: null,
      email: null,
      school: null,
      modifyName: false,
      modifyEmail: false,
    }
  },
  computed: {
    user() {
      return this.$root.currentUser.value
    }
  },
  methods: {
    async saveSchool() {
      await this.axios.post("/profile/school", {
        user_school: this.school,
        user_id: this.user.user_id,
      })
      this.$root.currentUser.value.user_school = this.school;
      this.$cookies.set("user", this.user);
    },
    async saveName() {
      await this.axios.post("/profile/name", {
        user_name: this.name,
        user_id: this.user.user_id,
      })
      this.$root.currentUser.value.user_name = this.name;
      this.$cookies.set("user", this.user);
      this.modifyName = false
    },
    async fetchInstituts() {
      const {data} = await this.axios.get("/profile/schools")
      this.instituts = data;
    }
  },
  watch: {
    user: {
      immediate: true,
      async handler(val) {
        await this.fetchInstituts();
        this.name = val.user_name;
        this.email = val.user_email;
        this.school = val.user_school;
      }
    },
  }
}
</script>

<style scoped>

</style>
